import { Box, Title } from "@mantine/core";
import { IUser } from "../types";
import { useUsersMutation } from "../hooks/users";
import { toast } from "../../../utils/toast";
import UserForm from "./forms/UserForm";
import { loginStyles } from "../styles/loginStyles";
import { Routes } from "../../../navigation/routes";
import { useLogin } from "../hooks/auth";
import { decryptData } from "../../../utils/crypto";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";

function Register() {
  const [user, setUser] = useState<IUser>();

  const login = useLogin({
    onSuccess: (response) => {
      const decryptedData = decryptData(`${response}`);

      if (decryptedData?.access_token) {
        sessionStorage.setItem(
          `${process.env.REACT_APP_ACCESS_TOKEN_NAME}`,
          decryptedData.access_token
        );
        secureLocalStorage.setItem(
          `${process.env.REACT_APP_SESSION_USER}`,
          decryptedData.user
        );

        toast.success();

        window.location.href = Routes.home;
      }
    },
    onError: () => {
      toast.show({
        title: "Erreur",
        message: "Erreur lors de l'authentification",
        color: "red",
      });
    },
  });

  const mutation = useUsersMutation({
    onSuccess: (response) => {
      if (response.status === true) {
        login.mutate({
          username: `${user?.email}`,
          email: `${user?.email}`,
          password: `${user?.password}`,
        });

        return null;
      }

      toast.error();
    },
    onError: () => {
      toast.error();
    },
  });

  const handleSubmit = (user: IUser) => {
    setUser(user);
    mutation.mutate({ ...user, ...{ role_id: 3 } });
  };

  const { classes } = loginStyles();

  return (
    <Box>
      <Title order={2} className={classes.title} ta="center" mt="md" mb={20}>
        Inscrivez-vous-vous!
      </Title>
      <UserForm
        onSubmit={handleSubmit}
        isLoading={mutation.isLoading || login.isLoading}
      />
    </Box>
  );
}

export default Register;
