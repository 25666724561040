import { ActionIcon, Center, Group, Modal } from "@mantine/core";
import { getShareUrl, SocialPlatforms } from "@phntms/react-share";
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandWhatsapp,
} from "@tabler/icons-react";

interface Props {
  url: string;
  opened: boolean;
  onClose: () => void;
}

const ShareLinksModal = ({ url, opened, onClose }: Props) => (
  <Modal opened={opened} onClose={onClose} size={"lg"} centered>
    <Center>
      <Group>
        <a
          href={getShareUrl(SocialPlatforms.Facebook, {
            url: url,
          })}
        >
          <ActionIcon bg={"white"} size={"lg"} radius={"lg"} style={styles.btn}>
            <IconBrandFacebook color="blue" />
          </ActionIcon>
        </a>
        <a
          href={getShareUrl(SocialPlatforms.Linkedin, {
            url: url,
          })}
        >
          <ActionIcon bg={"white"} size={"lg"} radius={"lg"} style={styles.btn}>
            <IconBrandLinkedin color="#1E88E5" />
          </ActionIcon>
        </a>
        <a
          href={getShareUrl(SocialPlatforms.Twitter, {
            url: url,
          })}
        >
          <ActionIcon bg={"white"} size={"lg"} radius={"lg"} style={styles.btn}>
            <IconBrandTwitter color="#2196F3" />
          </ActionIcon>
        </a>
        <a
          href={getShareUrl(SocialPlatforms.WhatsApp, {
            url: url,
          })}
        >
          <ActionIcon bg={"white"} size={"lg"} radius={"lg"} style={styles.btn}>
            <IconBrandWhatsapp color="green" />
          </ActionIcon>
        </a>
      </Group>
    </Center>
  </Modal>
);

const styles = {
  btn: { borderColor: "gray" },
};

export default ShareLinksModal;
