import { Center, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";
import { useItem, useItemParser } from "../../hooks/items";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../../store/store";
import { IItem } from "../../types";
import { useAddItemToShopCart } from "../../../shoppingCart/hooks/shoppingCart";
import { ItemActions } from "../../../../store/reducers/itemsReducer/actions";
import { ActionProps } from "../../../../store/types";
import { useDisclosure } from "@mantine/hooks";
import Details from "../../components/Details";

function ItemDetailsScreen() {
  const { uid } = useParams();
  const itemQuery = useItem(`${uid}`);
  const item = useItemParser(itemQuery.data);

  const dispatch = useDispatch();

  const shoppingCartItems = useSelector(
    (state: RootState) => state.shoppingCart.items
  );
  const favoriteItems = useSelector(
    (state: RootState) => state.itemsReducer.favorites
  );

  const socialShareModal = useDisclosure();

  const [cartItem, setCartItem] = useState<IItem | undefined>();
  const addItemToShopCart = useAddItemToShopCart();

  const [mainImage, setMainImage] = useState<string>(
    item?.images ? item.images[0].cover : ""
  );

  const isFavorite =
    favoriteItems.find((favoriteItem) => favoriteItem.id === item?.id)?.id !==
    undefined;

  const handleAddToCart = () => {
    cartItem && addItemToShopCart(cartItem, dispatch, shoppingCartItems);
  };

  const handleFavorite = (type: "add" | "remove") => {
    const action: ActionProps<ItemActions> = {
      type:
        type === "add" ? ItemActions.ADD_FAVORITE : ItemActions.REMOVE_FAVORITE,
      value: item,
    };
    dispatch(action);
  };

  useEffect(() => {
    setCartItem(item);
    setMainImage(item?.images ? item.images[0].cover : "");
  }, [itemQuery.data]);

  if (itemQuery.isLoading) {
    return (
      <Center mt={100}>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      {cartItem && item ? (
        <Details
          mainImage={mainImage}
          item={item}
          isFavorite={isFavorite}
          images={item?.images || []}
          colors={item?.colors || []}
          sizes={item?.sizes || []}
          socialShareModal={socialShareModal}
          cartItem={cartItem}
          onColorChange={(value) =>
            setCartItem({ ...cartItem, ...{ color: value } })
          }
          onSizeChange={(size) =>
            setCartItem({ ...cartItem, ...{ size: size } })
          }
          onImageClick={setMainImage}
          onFavorite={handleFavorite}
          onQuantityChange={(value) =>
            setCartItem({
              ...cartItem,
              ...{ quantity: parseInt(`${value}`) },
            })
          }
          onCartAdd={handleAddToCart}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default ItemDetailsScreen;
