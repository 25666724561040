import { Button, Text, Skeleton } from "@mantine/core";
import { Image, Box, Flex, Stack } from "@chakra-ui/react";
import { useItems } from "../../hooks/items";
import { Link } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";
import { useMemo } from "react";

export function LatestItem() {
  const itemsQuery = useItems({ per_page: 1 });
  const item =
    itemsQuery.data.length > 0
      ? itemsQuery.data[0]
      : { uid: "", images: [{ uid: "ccc", cover: "1.png" }], name: "" };

  const image = useMemo(() => {
    let cover = item.images ? item.images[0]?.cover : "1.png";
    cover = cover.split(".")[0];

    return `${cover}-min.png`;
  }, [item]);

  if (itemsQuery.isLoading) {
    return <Skeleton h={250} />;
  }

  return (
    <Link to={`${Routes.items}/${item.uid}`}>
      <Box
        h={250}
        sx={{ borderRadius: 10 }}
        bg={"rgba(0, 0, 0, 0.8)"}
        position={"relative"}
      >
        <Image
          src={`${process.env.REACT_APP_FILES_URL}/items/${image}`}
          style={{ objectFit: "contain" }}
          width={"100%"}
          height={"100%"}
        />
        <Flex
          sx={{ zIndex: 2000, position: "absolute", bottom: 0 }}
          align={"center"}
          justify={"space-between"}
          width={"full"}
          px={5}
        >
          <Stack spacing={0}>
            <Text mt={5} color="white" fw={"bold"} size={"lg"} w={160}>
              Article recemment ajouté
            </Text>
            <Text mt={5} color={"orange.9"} fw={"bold"} size={"md"} w={160}>
              {item.name}
            </Text>
          </Stack>
          <Button size="xs" radius={"lg"}>
            Les détails
          </Button>
        </Flex>
      </Box>
    </Link>
  );
}
