import { Group, UnstyledButton, Text, ThemeIcon, rem } from "@mantine/core";
import { IconClothesRackOff } from "@tabler/icons-react";
import { headerStyles } from "../../../../layouts/partials/styles/headerStyles";
import { Routes } from "../../../../navigation/routes";
import { IItemCategory } from "../../types";

interface Props {
  category: IItemCategory;
}

export default function CategoryItem({ category }: Props) {
  const { classes } = headerStyles();

  return (
    <UnstyledButton className={classes.subLink} key={category.name}>
      <a
        href={`${Routes.itemsCategories}/${category.uid}`}
        style={{ textDecoration: "none" }}
      >
        <Group noWrap align="center">
          <ThemeIcon size={25} variant="default" radius="md">
            <IconClothesRackOff size={rem(15)} color={"gray"} />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500} color="dimmed">
              {category.name}
            </Text>
          </div>
        </Group>
      </a>
    </UnstyledButton>
  );
}
