import {
  Group,
  Paper,
  Stack,
  Box,
  Flex,
  Avatar,
  Title,
  Text,
  Tabs,
  Skeleton,
} from "@mantine/core";
import { IconSettings, IconMoneybag } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import ResetPasswordFormModal from "../components/modals/ResetPasswordFormModal";
import { useAuth } from "../hooks/auth";
import OrdersScreen from "../../orders/screens/OrdersScreen";
import Addresses from "../../addresses/components/Addresses";
import Items from "../../items/components/Items";

function UserDetailsScreen() {
  const resetPasswordFormModal = useDisclosure();
  const { user } = useAuth();

  return (
    <>
      <Stack>
        <Paper withBorder m={5}>
          <Flex direction={"column"}>
            <Box bg={"gray"} miw={"100%"} w={"100%"} h={100} />
            <Flex justify={"space-between"} mx={20}>
              <Skeleton circle visible={false}>
                <Avatar
                  color="blue"
                  radius="100%"
                  size={80}
                  mt={-40}
                  tt={"uppercase"}
                >
                  {`${user?.name?.charAt(0)}${user?.name?.charAt(1)}`}
                </Avatar>
              </Skeleton>
            </Flex>
            <Stack mx={20} pb={10}>
              <Skeleton w={300} radius="xl" visible={false}>
                <Title size={25}>{user?.name}</Title>
              </Skeleton>
              <Skeleton w={200} radius="xl" visible={false}>
                <Text size={"xs"}>Lubumbashi, DRC Congo</Text>
              </Skeleton>
              <Group>
                <Text size={"xs"} fw={"bolder"}>
                  {user?.email}
                </Text>
                <Text size={"xs"}>.</Text>
                <Text size={"xs"} fw={"bold"}>
                  {user?.role?.name}
                </Text>
                <Text size={"xs"}>.</Text>
                <Text size={"xs"} color="green">
                  Actif
                </Text>
              </Group>
            </Stack>
          </Flex>
        </Paper>

        <Tabs defaultValue="userPayModes" mx={5}>
          <Tabs.List>
            <Tabs.Tab
              value="userPayModes"
              icon={<IconMoneybag size="0.8rem" />}
            >
              Commandes
            </Tabs.Tab>
            <Tabs.Tab value="favorites" icon={<IconSettings size="0.8rem" />}>
              Items Favoris
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
              Addresses
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="userPayModes" pt="xs">
            <OrdersScreen />
          </Tabs.Panel>

          <Tabs.Panel value="favorites" pt="xs">
            <Items isFavorites />
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs">
            <Addresses />
          </Tabs.Panel>
        </Tabs>
      </Stack>
      <ResetPasswordFormModal
        opened={resetPasswordFormModal[0]}
        onClose={resetPasswordFormModal[1].close}
      />
    </>
  );
}

export default UserDetailsScreen;
