import { Center } from "@mantine/core";
import Login from "../components/Login";
import { loginStyles } from "../styles/loginStyles";
import Logo from "../../../components/Logo";
import { useParams } from "react-router-dom";
import Register from "../components/Register";
import { Box } from "@chakra-ui/react";

function AuthScreen() {
  const { classes } = loginStyles();
  const { type } = useParams();

  return (
    <Box className={classes.wrapper}>
      <Center>
        <Logo size={90} mode="dark" />
      </Center>
      <Box mx={{ base: 10, md: 200, xl: 400, lg: 400 }}>
        {type === "login" ? <Login /> : <Register />}
      </Box>
    </Box>
  );
}

export default AuthScreen;
