import {
  UnstyledButton,
  Divider,
  Center,
  Box,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  Button,
  Text
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBrandWhatsapp, IconChevronDown } from "@tabler/icons-react";
import { headerStyles } from "./styles/headerStyles";
import { useCategories } from "../../features/items/hooks/categories";
import CategoryItem from "../../features/items/components/CategoryItem";

interface Props {
  opened?: boolean;
  close: () => void;
}

export function MobileDrawer({ opened = false, close }: Props) {
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = headerStyles();
  const categoriesQuery = useCategories({ per_page: 100 });

  return (
    <Drawer
      opened={opened}
      onClose={close}
      size="100%"
      padding="md"
      title="Navigation"
      className={classes.hiddenDesktop}
      zIndex={1000000}
    >
      <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
        <Divider
          my="sm"
          color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
        />
        <UnstyledButton className={classes.link} onClick={toggleLinks}>
          <Center inline>
            <Box component="span" mr={5}>
              Catégories
            </Box>
            <IconChevronDown size={16} color={theme.fn.primaryColor()} />
          </Center>
        </UnstyledButton>
        <Collapse in={linksOpened}>
          {categoriesQuery.data.map((item) => (
            <CategoryItem key={item.id} category={item} />
          ))}
        </Collapse>
        <Text className={classes.link}>
          Contact
        </Text>
        <a href="whatsapp://send?text=hello&phone=243844303021">
          <Button
            variant="outline"
            color="green"
            ml={10}
            rightIcon={<IconBrandWhatsapp />}
          >
            Contactez-nous via
          </Button>
        </a>
        <Divider
          my="sm"
          color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
        />
      </ScrollArea>
    </Drawer>
  );
}
