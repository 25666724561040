export const endPoints = {
    login: 'login',
    otpPasswordReset: 'otp_password_reset',
    users: 'users',
    register: 'register',
    roles: 'roles',
    features: 'features',
    permissions: 'role_permissions',
    cities: 'cities',
    currencies: 'currencies',
    payModes: 'pay_modes',
    userPayModes: 'user_pay_modes',
    rates: 'rates',
    items: 'items',
    promotions: 'promotions',
    itemCategories: 'categories',
    addresses: 'addresses',
    orders: 'orders',
};