import { Box, Center, Loader, Stack, Text } from "@mantine/core";
import { Image } from "@chakra-ui/react";
import Items from "../../components/Items";
import { useParams } from "react-router-dom";
import { useCategories, useCategory } from "../../hooks/categories";
import { useQuery } from "../../../../hooks/useQuery";
import Categories from "../../components/Categories";

function ItemsScreen() {
  const { uid } = useParams();
  const categoryQuery = useCategory(`${uid}`);
  const query = useQuery();

  const category = categoryQuery.data;

  const categoriesQuery = useCategories({
    key: "category_id",
    value: category?.id || -1,
    per_page: 1,
  });

  if (categoryQuery.isLoading) {
    return (
      <Center mt={100}>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <Box w={"100%"} h={100} bg={"#f1f1f3"} sx={{ position: "relative" }}>
        <Box
          sx={{ position: "absolute" }}
          w={"100%"}
          h={100}
          bg={"rgba(0, 0, 0, 0.5)"}
        />
        <Image
          src={
            category?.cover
              ? `${process.env.REACT_APP_FILES_URL}/categories/${category.cover}`
              : "https://images.unsplash.com/photo-1555529771-835f59fc5efe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80"
          }
          style={{ objectFit: "cover" }}
          width={"100%"}
          height={"100%"}
        />
        <Stack sx={{ position: "absolute", top: 20 }} w={"100%"}>
          <Text size={"xl"} color="white" mx={"auto"} fw={"bold"}>
            {category?.name}
          </Text>
        </Stack>
      </Box>
      <Stack mx={10}>
        {!categoriesQuery.isLoading && categoriesQuery.data.length > 0 ? (
          <Categories categoryId={category?.id || -1} />
        ) : (
          <></>
        )}
        <Items
          keyName={uid === "tops" ? "is_top" : "category_id"}
          keyValue={uid === "tops" ? 1 : category?.id}
          keyword={query.get("keyword")}
        />
      </Stack>
    </Stack>
  );
}

export default ItemsScreen;
