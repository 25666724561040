import { Carousel } from "@mantine/carousel";
import CategoriesList from "../CategoriesList";
import { MediaQuery } from "@mantine/core";

interface Props {
  categoryId?: number | null;
}

function Categories({ categoryId = 0 }: Props) {
  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Carousel
          withIndicators
          height={90}
          slideSize="3.333333%"
          slideGap="md"
          align="start"
          slidesToScroll={3}
        >
          <CategoriesList categoryId={categoryId} />
        </Carousel>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Carousel
          withIndicators
          height={90}
          slideSize="33.333333%"
          slideGap="md"
          align="start"
          slidesToScroll={3}
        >
          <CategoriesList categoryId={categoryId} />
        </Carousel>
      </MediaQuery>
    </>
  );
}

export default Categories;
