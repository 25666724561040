import { Table } from "@tanstack/react-table";
import { Box, Button, Flex, ActionIcon } from "@mantine/core";
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";

interface Props<T extends object> {
  table: Table<T>;
  onPageChange: (page: number) => void;
}

function Pagination<T extends object>({ table, onPageChange }: Props<T>) {
  return (
    <Box py={5} px={2}>
      <Flex gap={2}>
        <ActionIcon
          className="cursor-pointer rounded border p-1"
          onClick={() => {
            onPageChange(1);
            table.setPageIndex(0);
          }}
          disabled={!table.getCanPreviousPage()}
        >
          <IconChevronsLeft size="1.125rem" />
        </ActionIcon>
        {[...Array(table.getPageCount())].map((page, index) => (
          <Button
            onClick={() => {
              table.setPageIndex(index);
              onPageChange(index + 1);
            }}
            bg={
              table.getState().pagination.pageIndex === index ? "blue" : "gray"
            }
            key={index + 1}
          >
            {index + 1}
          </Button>
        ))}
        <ActionIcon
          className="cursor-pointer rounded border p-1"
          onClick={() => {
            table.setPageIndex(table.getPageCount() - 1);
            onPageChange(table.getPageCount());
          }}
          disabled={!table.getCanNextPage()}
          aria-label="left"
        >
          <IconChevronsRight size="1.125rem" />
        </ActionIcon>
      </Flex>
    </Box>
  );
}

export default Pagination;
