import React from "react";
import { AppShell, Stack } from "@mantine/core";
import { ReactNode } from "react";
import { excludeInAppLayoutRoutes } from "../navigation/routes";
import { useAuth } from "../features/auth/hooks/auth";
import { AppHeader } from "./partials/AppHeader";
import AppFooter from "./partials/AppFooter";
import { Aside } from "./partials";
import { Box } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
}

function AppLayout({ children }: Props) {
  const location = window.location;
  const [pathname, setPathname] = React.useState<string>(location.pathname);
  const auth = useAuth();

  React.useEffect(() => {
    setPathname(location.pathname);
  }, [pathname, location, auth.accessToken]);

  if (excludeInAppLayoutRoutes.includes(pathname)) return <>{children}</>;

  return (
    <AppShell
      header={<AppHeader />}
      aside={
        <Stack p={4}>
          <Aside />
        </Stack>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[8] : "white",
          padding: 0,
        },
      })}
    >
      <Stack>
        <Box>{children}</Box>
        <AppFooter />
      </Stack>
    </AppShell>
  );
}

export default AppLayout;
