import { api } from "../../../api/api";
import { endPoints } from "../../../api/endPoints";
import { IPaginationQueryParams, IQueryResults, PostPutResponse } from "../../../api/types";
import { IAddress } from "../types";

export const addressesService = {
  create: (payLoad: IAddress): Promise<PostPutResponse<IAddress>> =>
    api.post({ endPoint: endPoints.addresses, data: payLoad }),
  findAll: (params?: IPaginationQueryParams): Promise<IQueryResults<IAddress>> =>
    api.get({ endPoint: endPoints.addresses, params: params }),
  find: (uid: string): Promise<IAddress> =>
    api.get({ endPoint: `${endPoints.addresses}/${uid}` }),
  update: (payLoad: IAddress, uid: string): Promise<PostPutResponse<IAddress>> =>
    api.post({ endPoint: `${endPoints.addresses}/${uid}`, data: payLoad, method: "PUT" }),
  delete: (uid: string): Promise<PostPutResponse<IAddress>> =>
    api.get({ endPoint: `${endPoints.addresses}/${uid}`, method: "DELETE" }),
};