import { useState } from "react";
import { Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IOrder } from "../../types";
import CrudHeader from "../../../../components/CrudHeader";
import OrdersTable from "../../components/tables/OrdersTable";
import { useAuth } from "../../../auth/hooks/auth";

function OrdersScreen() {
  const orderFormModal = useDisclosure(false);
  const [order, setOrder] = useState<IOrder>();
  const [selectedOrders, setSelectedOrders] = useState<IOrder[]>([]);
  const [showActions, setShowActions] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>();
  const { user } = useAuth();

  const handleSelection = (orders: IOrder[]) => {
    orders.length > 0 ? setShowActions(true) : setShowActions(false);
    setSelectedOrders(orders);
  };

  const handleEdit = (order: IOrder) => {
    setOrder(order);
    orderFormModal[1].open();
  };

  const handleAdd = () => {
    setOrder(undefined);
    orderFormModal[1].open();
  };

  return (
    <Stack>
      <CrudHeader
        onButtonClick={handleAdd}
        buttonTitle="Nouvelle commande"
        showActions={showActions}
        onSearch={(keyword) => setKeyword(keyword)}
        canCreate={false}
      />
      <OrdersTable
        onEdit={handleEdit}
        onSelect={handleSelection}
        filters={{ keyword: keyword, userId: parseInt(`${user.id}`) }}
      />
    </Stack>
  );
}

export default OrdersScreen;
