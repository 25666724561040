import { ActionIcon, Group, TextInput, useMantineTheme } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useState } from "react";

interface Props {
  onChange?: (keyword: string) => void;
  onKeyDown?: (keyword: string) => void;
}

export default function InputSearch({ onChange, onKeyDown }: Props) {
  const theme = useMantineTheme();
  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e.target.value);
  };

  const handleKeyDown = () => {
    onKeyDown?.(value);
  };

  return (
    <Group spacing={0}>
      <TextInput
        icon={<IconSearch size="1.1rem" stroke={1.5} />}
        radius={'md'}
        size="xs"
        placeholder="Rechercher un article..."
        w={"100%"}
        onChange={handleChange}
        type="search"
        rightSection={
          <ActionIcon
            onClick={handleKeyDown}
            bg={theme.primaryColor}
            p={5}
            size={"lg"}
          >
            <IconSearch color="white" />
          </ActionIcon>
        }
      />
    </Group>
  );
}
