import { Box, Flex, Stack, Text, useMantineTheme } from "@mantine/core";
import OrderPayStatus from "../OrderPayStatus";
import { IOrder } from "../../types";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";

interface Props {
  order: IOrder;
}

function PaymentDetails({ order }: Props) {
  const theme = useMantineTheme();
  const toCurrencyFormat = useCurrencyFormat();

  return (
    <Box bg={theme.colorScheme === "light" ? "#F5F5F5" : "dark"} p={10}>
      <Flex justify={"space-between"}>
        <Text fw={500}>Paiement</Text>
        <OrderPayStatus isPaid={order?.isPaid} type="badge" />
      </Flex>
      <Stack>
        <Flex justify={"space-between"}>
          <Text size={"xs"}>Mode de paiement</Text>
          <Text
            size={"xs"}
            color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
          >
            cash
          </Text>
        </Flex>
        <Flex justify={"space-between"}>
          <Text size={"xs"}>Total</Text>
          <Text
            size={"xs"}
            color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
          >
            {toCurrencyFormat(order?.amount || 0, "")} CDF
          </Text>
        </Flex>
      </Stack>
    </Box>
  );
}

export default PaymentDetails;
