import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { Table } from "../../../../components/tables/Table";
import { filterFns } from "../../../../components/tables/filterFns";
import { Anchor, Group, Text } from "@mantine/core";
import { Routes } from "../../../../navigation/routes";
import { IOrder } from "../../types";
import { useOrders } from "../../hooks/orders";
import OrderStatus from "../OrderStatus";
import { Link } from "react-router-dom";
import OrderPayStatus from "../OrderPayStatus";

interface Props {
  filters: {
    keyword?: string;
    userId?: number;
  };
  onSelect?: (orders: IOrder[]) => void;
  onEdit?: (order: IOrder) => void;
}

function OrdersTable({ filters, onSelect, onEdit }: Props) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { keyword, userId } = filters;

  const dataQuery = useOrders({
    page: currentPage,
    per_page: 15,
    field: "number",
    keyword: keyword,
    key: 'user_id',
    value: userId,
  });

  const { meta, data, isLoading, isFetching, errorResponse } = dataQuery;

  const cols = React.useMemo<ColumnDef<IOrder>[]>(
    () => [
      {
        header: "Date",
        cell: (row) => (
          <Anchor component="span" fz="sm">
            {`${moment(`${row.renderValue()}`).format("DD/MM/yyyy H:m:s")}`}
          </Anchor>
        ),
        accessorKey: "createdAt",
      },
      {
        header: "N°",
        cell: (row) => (
          <Link to={`${Routes.orders}/${row.row.original.uid}`}>
            #{`${row.renderValue()}`}
          </Link>
        ),
        accessorKey: "number",
      },
      {
        header: "Total",
        cell: (row) => <span>{`${row.renderValue()}`}CDF</span>,
        accessorKey: "amount",
      },
      {
        header: "Moyen de paiement",
        cell: (row) => (
          <Group>
            <Text>Cash</Text>
            <OrderPayStatus isPaid={row.row.original.isPaid} type="badge" />
          </Group>
        ),
        accessorKey: "userPayMode.payMode.name",
      },
      {
        header: "Statut",
        cell: (row) => (
          <OrderStatus status={parseInt(`${row.renderValue()}`)} type="badge" />
        ),
        accessorKey: "status",
      },
    ],
    []
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Table
        columns={cols}
        data={data || []}
        meta={{
          currentPage: meta?.current_page,
          pageSize: meta?.per_page,
          total: meta?.total,
        }}
        filterFn={filterFns.contains}
        isLoading={isLoading}
        isFetching={isFetching}
        onPageChange={handlePageChange}
        onEdit={onEdit}
        onSelect={onSelect}
        error={errorResponse}
        isEditable={false}
        isSelectable={false}
      />
    </>
  );
}

export default OrdersTable;
