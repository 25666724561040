import { Box, Card, Stack } from "@chakra-ui/react";
import { Group, Skeleton, Text } from "@mantine/core";
import { IconCategory } from "@tabler/icons-react";
import CategoryItem from "../CategoryItem";
import { useCategories } from "../../hooks/categories";

export function TopCategories() {
  const categoriesQuery = useCategories({ per_page: 6 });

  return (
    <Card borderWidth={0.2} borderRadius={"lg"}>
      <Box w={"full"} bg={"gray.50"} p={2}>
        <Group spacing={4} align="center">
          <IconCategory size={15} />
          <Text size={"sm"} fw={500}>
            Top Catégories
          </Text>
        </Group>
      </Box>
      <Stack gap={0}>
        {categoriesQuery.data.map((item) => (
          <CategoryItem key={item.id} category={item} />
        ))}
      </Stack>
      {categoriesQuery.isLoading ? (
        [1, 2, 3, 4, 5, 6].map((value) => (
          <Skeleton
            key={value}
            height={8}
            mt={6}
            width="80%"
            radius="xl"
            mx={5}
          />
        ))
      ) : (
        <></>
      )}
    </Card>
  );
}
