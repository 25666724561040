import {
  ActionIcon,
  Box,
  Stack,
  Text,
  useMantineTheme,
  Badge,
} from "@mantine/core";
import { Flex, Image } from "@chakra-ui/react";
import { IItem } from "../../types";
import { IconHeart, IconHeartFilled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";
import { Carousel } from "@mantine/carousel";
import { useItemParser } from "../../hooks/items";

interface Props {
  item: IItem;
}

function Item({ item }: Props) {
  const theme = useMantineTheme();
  const favoriteItems = useSelector(
    (state: RootState) => state.itemsReducer.favorites
  );
  const toCurrencyFormat = useCurrencyFormat();
  const isFavorite =
    favoriteItems.find((favoriteItem) => favoriteItem.id === item.id)?.id !==
    undefined;

  item = useItemParser(item) || item;

  return (
    <Link
      to={`${Routes.items}/${item.uid}`}
      style={{ textDecoration: "none", color: "black" }}
    >
      <Stack sx={{ position: "relative" }}>
        <ActionIcon
          sx={{ position: "absolute", top: 0, right: 0 }}
          bg={"white"}
          radius={"lg"}
          m={4}
        >
          {isFavorite ? (
            <IconHeartFilled style={{ color: theme.primaryColor }} />
          ) : (
            <IconHeart color={theme.primaryColor} />
          )}
        </ActionIcon>
        <Box
          h={250}
          bg={"#f1f1f3"}
          sx={{ borderRadius: 10, overflow: "hidden" }}
        >
          <Carousel withIndicators withControls={false} h={250}>
            {item.images?.map((image) => (
              <Carousel.Slide key={image.cover}>
                <Image
                  src={`${process.env.REACT_APP_FILES_URL}/items/${image.cover}`}
                  style={{ objectFit: "scale-down" }}
                  width={"100%"}
                  height={"100%"}
                  alt={item.name}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Box>
        <Stack spacing={0}>
          
          <Text>{item.name}</Text>
          <Flex align={"center"} justify={"space-between"}>
            <Text size={'xs'} color="gray">Tailles:</Text>
            <Badge tt={"uppercase"} size="xs">
              {item.sizes?.map((size) => `${size}.`)}
            </Badge>
          </Flex>
          <Text fw={"bold"}>{toCurrencyFormat(item.salePrice, "CDF")}</Text>
        </Stack>
      </Stack>
    </Link>
  );
}

export default Item;