import { Flex } from "@chakra-ui/react";
import { Badge } from "@mantine/core";
import {
  IconCategory2,
  IconMan,
  IconWoman,
  TablerIconsProps,
} from "@tabler/icons-react";

interface Props {
  active?: number;
  onChange?: (value?: number) => void;
}

export function BaseCategories({ active, onChange }: Props) {
  return (
    <Flex gap={3} wrap={'wrap'}>
      {data.map((category) => (
        <Badge
          key={category.label}
          tt={"capitalize"}
          variant={active === category.value ? "filled" : "light"}
          leftSection={<category.Icon size={13} />}
          onClick={() => onChange?.(category.value)}
          color="dark"
          p={'sm'}
        >
          {category.label}
        </Badge>
      ))}
    </Flex>
  );
}

const data: Array<{
  label: string;
  value: number | undefined;
  Icon: (props: TablerIconsProps) => JSX.Element;
}> = [
  {
    label: "Tout",
    value: undefined,
    Icon: IconCategory2,
  },
  {
    label: "hommes",
    value: 1,
    Icon: IconMan,
  },
  {
    label: "femmes",
    value: 2,
    Icon: IconWoman,
  },
];
