import { z } from "zod";
import { useForm, zodResolver } from "@mantine/form";
import { TextInput, Button, Box, Group, NativeSelect } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import { IAddress } from "../../types";
import { useCities } from "../../../cities/hooks/cities";
import { useSelectMemo } from "../../../../hooks/useSelectMemo";

interface Props {
  onSubmit: (data: IAddress) => void;
  isLoading: boolean;
  address?: IAddress;
}

function AddressForm({ onSubmit, isLoading, address }: Props) {
  const schema = z.object({
    street: z.string().min(3, { message: "Minimum 3 caracteres" }),
    reference: z.string().min(3, { message: "Minimum 3 caracteres" }),
    district: z.string().min(3, { message: "Minimum 3 caracteres" }),
    cityId: z.string().optional(),
  });

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      street: address?.street || "",
      reference: address?.reference || "",
      district: address?.district || "",
      cityId: address?.cityId || "1",
    },
  });

  const citiesQuery = useCities();
  const cities = useSelectMemo({ data: citiesQuery.data });

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <Group grow>
          <TextInput
            withAsterisk
            label="Rue|Avenue"
            placeholder="Rue|Avenue..."
            {...form.getInputProps("street")}
            icon={<IconUser size={"1rem"} />}
          />
          <TextInput
            withAsterisk
            label="Reference"
            placeholder="Reference..."
            {...form.getInputProps("reference")}
            icon={<IconUser size={"1rem"} />}
          />
        </Group>
        <TextInput
          withAsterisk
          label="Quartier"
          placeholder="Quartier..."
          {...form.getInputProps("district")}
          icon={<IconUser size={"1rem"} />}
        />
        <NativeSelect
          label="Ville"
          placeholder="Choisissez une ville"
          data={cities}
          {...form.getInputProps("cityId")}
        />

        <Group mt="xl" position="right">
          <Button mt="xl" size="sm" type="submit" loading={isLoading}>
            Enregistrer
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default AddressForm;
