import { Stack, Box, Center, Text } from "@mantine/core";
import CartItem from "../CartItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

function CartItems() {
  const items = useSelector((state: RootState) => state.shoppingCart.items);

  if (items.length === 0) {
    return (
      <Center>
        <Text color="red">Votre Panier est vide</Text>
      </Center>
    );
  }
  return (
    <>
      <Stack>
        {items.map((item) => (
          <Box key={item.id}>
            <CartItem item={item} />
          </Box>
        ))}
      </Stack>
    </>
  );
}

export default CartItems;
