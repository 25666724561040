import { IItem } from "../../../features/items/types";
import { ActionProps } from "../../types";
import { ShoppingCartActions } from "./actions";

interface Props {
  items: IItem[];
}

const initialState: Props = {
  items: [],
};

export function shoppingCartReducer(
  state = initialState,
  action: ActionProps<ShoppingCartActions>
) {
  let nextState: typeof initialState;

  switch (action.type) {
    case ShoppingCartActions.ADD_ITEM:
      nextState = {
        ...state,
        items: [...state.items, action.value],
      };

      return nextState;

    case ShoppingCartActions.EDIT_ITEM:
      nextState = {
        ...state,
        items: state.items.map((item) => {
          if (item.uid === action.value?.uid) {
            return {
              ...item,
              ...action.value,
            };
          }

          return item;
        }),
      };

      return nextState;

    case ShoppingCartActions.REMOVE_ITEM:
      nextState = {
        ...state,
        items: state.items.filter((item) => item.uid !== action.value?.uid),
      };

      return nextState;
    case ShoppingCartActions.REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}
