import {
  Stack,
  Group,
  ActionIcon,
  Flex,
  Button,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import Address from "../../../addresses/components/Address";
import AddressesModal from "../../../addresses/components/modals/AddressesModal";
import PayModeItem from "../../../payModes/components/PayModeItem";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";
import { IOrder } from "../../../orders/types";
import ShopCart from "../ShopCart";
import { IAddress } from "../../../addresses/types";
import { ModalProps } from "../../../types";

interface Props {
  order: IOrder;
  totalAmount: number;
  isLoading: boolean;
  onSubmit: () => void;
  onAddressSelection: (address: IAddress) => void;
  addressesModal: ModalProps;
}

function CheckoutUI({
  order,
  addressesModal,
  totalAmount,
  isLoading,
  onSubmit,
  onAddressSelection,
}: Props) {
  const navigate = useNavigate();
  const toCurrencyFormat = useCurrencyFormat();
  const theme = useMantineTheme();

  return (
    <>
      <Stack px={15} py={5} bg={"#f9f9f9"} mih={"100vh"}>
        <Group>
          <ActionIcon bg={"#f1f1f3"} radius={"lg"} onClick={() => navigate(-1)}>
            <IconChevronLeft />
          </ActionIcon>
          <Text size={"xl"} fw={"bold"}>
            Confirmation
          </Text>
        </Group>
        <ShopCart items={order.items || []} />
        <Stack>
          <Text fw={"bold"}>Adresse de livraison</Text>
          {order.address ? (
            <Address address={order.address} onClick={addressesModal[1].open} />
          ) : (
            <></>
          )}
        </Stack>
        <Stack>
          <Text fw={"bold"}>Mode de paiement</Text>
          <PayModeItem />
        </Stack>
        <Flex justify={"space-between"}>
          <Text fw={"bold"} color="gray">
            Total
          </Text>
          <Group spacing={1}>
            <Text color={theme.primaryColor} fw={"bold"}>
              CDF
            </Text>
            <Text fw={"bold"}>{toCurrencyFormat(totalAmount, "")}</Text>
          </Group>
        </Flex>
        <Button
          size="md"
          onClick={onSubmit}
          disabled={order?.address?.city?.id === undefined}
          loading={isLoading}
        >
          Confirmer la commande
        </Button>
      </Stack>
      <AddressesModal
        opened={addressesModal[0]}
        onClose={addressesModal[1].close}
        onItemSelect={onAddressSelection}
      />
    </>
  );
}

export default CheckoutUI;
