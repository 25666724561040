import { Group, ActionIcon, Text } from "@mantine/core";
import { IItem } from "../../types";

export interface ItemSizesProps {
  sizes: string[];
  cartItem: IItem;
  onSizeChange: (size: string) => void;
}

function ItemSizes({ sizes, cartItem, onSizeChange }: ItemSizesProps) {
  return (
    <Group>
      {sizes?.map((size) => (
        <ActionIcon
          key={size}
          bg={size === cartItem?.size ? "dark" : "#f1f1f3"}
          radius={"lg"}
          onClick={() => onSizeChange(size)}
        >
          <Text>{size}</Text>
        </ActionIcon>
      ))}
    </Group>
  );
}

export default ItemSizes;
