import {
  Group,
  Box,
  Stack,
  ActionIcon,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Image } from "@chakra-ui/react";
import { IconTrash } from "@tabler/icons-react";
import InputQuantity from "../../../../components/InputQuantity";
import { IItem } from "../../../items/types";
import { useDispatch } from "react-redux";
import { ActionProps } from "../../../../store/types";
import { ShoppingCartActions } from "../../../../store/reducers/shoppingCartReducer/actions";
import ColorSelect from "../../../../components/ColorSelect";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";

interface Props {
  item: IItem;
  size?: number;
  w?: number | string;
  showQuantityInput?: boolean;
}

function CartItem({
  item,
  size = 200,
  w = "45%",
  showQuantityInput = true,
}: Props) {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const toCurrencyFormat = useCurrencyFormat();

  const handleQuantityChange = (value: number | "") => {
    const action: ActionProps<ShoppingCartActions> = {
      type: ShoppingCartActions.EDIT_ITEM,
      value: { ...item, ...{ quantity: value } },
    };
    dispatch(action);
  };

  const handleRemove = () => {
    const action: ActionProps<ShoppingCartActions> = {
      type: ShoppingCartActions.REMOVE_ITEM,
      value: item,
    };
    dispatch(action);
  };

  return (
    <Box>
      <Group>
        <Box w={w} h={size} bg={"#f1f1f3"} sx={{ borderRadius: 15 }}>
          <Image
            src={`${process.env.REACT_APP_FILES_URL}/items/${
              item?.images ? item.images[0].cover : ""
            }`}
            alt="Panda"
            style={{
              objectFit: "scale-down",
            }}
            width={"100%"}
            height={"100%"}
          />
        </Box>
        <Stack spacing={7}>
          <Text
            maw={size > 200 ? 100 : 50}
            fw={"bold"}
            size={size < 200 ? "xs" : "md"}
          >
            {item.name}
          </Text>
          {size >= 200 && (
            <>
              <Text fw={"bold"} color="gray" size={"xs"}>
                Taille: {item.size}
              </Text>
              <Group spacing={2}>
                <Text fw={"bold"} color="gray" size={"xs"}>
                  Couleur:
                </Text>
                <ColorSelect color={item.color} size={"xs"} />
              </Group>
            </>
          )}

          <Group spacing={1}>
            <Text
              color={theme.primaryColor}
              fw={"bold"}
              size={size < 200 ? "xs" : "md"}
            >
              CDF
            </Text>
            <Text fw={"bold"} size={size < 200 ? "xs" : "md"}>
              {toCurrencyFormat(item.salePrice * item.quantity, "")}
            </Text>
          </Group>
          {showQuantityInput && (
            <Group>
              <Box w={95} mt={5}>
                <InputQuantity
                  defaultValue={item.quantity}
                  onChange={handleQuantityChange}
                />
              </Box>
              <ActionIcon onClick={handleRemove}>
                <IconTrash color={theme.primaryColor} />
              </ActionIcon>
            </Group>
          )}
        </Stack>
      </Group>
    </Box>
  );
}

export default CartItem;
