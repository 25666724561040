import { Box, Flex, Stack, Text, useMantineTheme } from "@mantine/core";
import moment from "moment";
import OrderStatus from "../OrderStatus";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";
import { IOrder } from "../../types";

interface Props {
  order: IOrder;
}

function Details({ order }: Props) {
  const theme = useMantineTheme();
  const toCurrencyFormat = useCurrencyFormat();

  return (
    <Box bg={theme.colorScheme === "light" ? "#F5F5F5" : "dark"} p={10}>
      <Flex justify={"space-between"}>
        <Text fw={500}>Resumé</Text>
        <OrderStatus status={order?.status || 0} type="badge" />
      </Flex>
      <Stack>
        <Flex justify={"space-between"}>
          <Text size={"xs"}>Date</Text>
          <Text
            size={"xs"}
            color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
          >
            {moment(`${order?.createdAt}`).format("DD/MM/YYYY H:m:s")}
          </Text>
        </Flex>
        <Flex justify={"space-between"}>
          <Text size={"xs"}>N° Commande</Text>
          <Text
            size={"xs"}
            color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
          >
            #{order?.number}
          </Text>
        </Flex>
        <Flex justify={"space-between"}>
          <Text size={"xs"}>Sous Total</Text>
          <Text
            size={"xs"}
            color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
          >
            {toCurrencyFormat(order?.amount || 0, "")} CDF
          </Text>
        </Flex>
        <Flex justify={"space-between"}>
          <Text size={"xs"}>Frais de Livraison</Text>
          <Text
            size={"xs"}
            color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
          >
            {order?.deliveryFees} CDF
          </Text>
        </Flex>
      </Stack>
    </Box>
  );
}

export default Details;
