import {
  Badge,
  Box,
  Divider,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { useOrder, useParseOrder } from "../hooks/orders";
import OrderItemsTable from "../components/tables/OrderItemsTable";
import PaymentDetails from "../components/PaymentDetails";
import Details from "../components/Details";

function OrderDetailsScreen() {
  const { uid } = useParams();
  const orderQuery = useOrder(`${uid}`);
  const order = useParseOrder(orderQuery.data);
  const theme = useMantineTheme();

  if (orderQuery.isLoading) {
    return (
      <Flex justify={"center"} mt={50}>
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      <Stack align="center" mx={25}>
        <Group>
          <Stack>
            <Group>
              <Text size={"xl"} fw={500} color="orange">
                #{order?.number}
              </Text>
            </Group>
            <Stack>
              {order && <Details order={order} />}
              {order && <PaymentDetails order={order} />}
            </Stack>
            <Box
              bg={
                theme.colorScheme === "light"
                  ? theme.colorScheme === "light"
                    ? "#F5F5F5"
                    : "dark"
                  : "dark"
              }
              p={10}
            >
              <Flex justify={"space-between"}>
                <Text fw={500}>Les Items</Text>
                <Badge tt={"lowercase"}>{order?.items?.length} item(s)</Badge>
              </Flex>
              <Divider />
              <OrderItemsTable items={order?.items || []} />
            </Box>
            <Box bg={theme.colorScheme === "light" ? "#F5F5F5" : "dark"} p={10}>
              <Stack>
                <Text size={"xl"} fw={500}>
                  Adresse de livraison
                </Text>
                <Text
                  size={"xs"}
                >{`${order?.address?.reference} ${order?.address?.street}, ${order?.address?.district}, ${order?.address?.city?.name}`}</Text>
              </Stack>
            </Box>
          </Stack>
        </Group>
      </Stack>
    </>
  );
}

export default OrderDetailsScreen;
