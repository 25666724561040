import { Flex, Stack } from "@chakra-ui/react";
import { Carousel } from "@mantine/carousel";
import { MediaQuery, Skeleton } from "@mantine/core";
import { IItem } from "../../types";
import TopItem from "../TopItem";

interface Props {
  items: IItem[];
  isLoading?: boolean;
}

export function ItemListCarousel({ items, isLoading = false }: Props) {
  if (isLoading) {
    return (
      <Flex gap={2}>
        {[1, 2].map((value) => (
          <Flex gap={2}>
            <Skeleton key={value} w={120} h={120} />
            <Stack>
              <Skeleton w={120} height={8} mt={6} radius="xl" />
              <Skeleton height={8} mt={6} width="70%" radius="xl" />
              <Skeleton height={8} mt={6} width="60%" radius="xl" />
            </Stack>
          </Flex>
        ))}
      </Flex>
    );
  }
  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Carousel
          withControls
          height={120}
          slideSize="3.333333%"
          slideGap="md"
          align="start"
          slidesToScroll={3}
        >
          {items.map((item) => (
            <Carousel.Slide key={item.id}>
              <TopItem item={item} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Carousel
          withControls={false}
          height={120}
          slideSize="33.333333%"
          slideGap="md"
          align="start"
          slidesToScroll={3}
        >
          {items.map((item) => (
            <Carousel.Slide key={item.id}>
              <TopItem item={item} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </MediaQuery>
    </>
  );
}
