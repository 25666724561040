import { ActionIcon, Group, Divider } from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
} from "@tabler/icons-react";
import Logo from "../../components/Logo";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";

function AppFooter() {
  return (
    <Stack
      w={"full"}
      bg={"gray.50"}
      position={"absolute"}
      bottom={-80}
      px={{ base: 3, md: 150, xl: 150 }}
      gap={8}
      py={{ base: 0, md: 10, xl: 10 }}
    >
      <Box>
        <Stack
          alignItems={{ base: "center", md: "flex-start", xl: "flex-start" }}
        >
          <Logo mode="dark" />
          <Text
            size="xs"
            color="gray"
            textAlign={{ base: "center", md: "start", xl: "start" }}
          >
            Payez des habits de friperie de qualité à un prix très abordable.
          </Text>
        </Stack>
      </Box>
      <Divider />
      <Flex
        justify={"space-between"}
        direction={{ base: "column", md: "row", xl: "row" }}
        alignItems={{ base: "center", md: "flex-start", xl: "flex-start" }}
      >
        <Text color="gray" size="sm">
          © 2023 SecHand. Tout droit reservé.
        </Text>

        <Group spacing={0} noWrap>
          <ActionIcon size="lg">
            <IconBrandTwitter size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandYoutube size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size="1.05rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </Flex>
    </Stack>
  );
}

export default AppFooter;
