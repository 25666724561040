import { RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useCalculateShopCartSubTotal } from "../../../shoppingCart/hooks/shoppingCart";
import { useAuth } from "../../../auth/hooks/auth";
import { useDisclosure } from "@mantine/hooks";
import { IAddress } from "../../../addresses/types";
import { ActionProps } from "../../../../store/types";
import { useOrdersMutation } from "../../../orders/hooks/orders";
import { OrderActions } from "../../../../store/reducers/order/actions";
import { IOrder } from "../../../orders/types";
import { toast } from "../../../../utils/toast";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";
import { useEffect, useMemo } from "react";
import { ShoppingCartActions } from "../../../../store/reducers/shoppingCartReducer/actions";
import CheckoutUI from "../../components/CheckoutUI";

function CheckoutScreen() {
  const items = useSelector((state: RootState) => state.shoppingCart.items);
  const calculateShopCartSubTotal = useCalculateShopCartSubTotal();
  const auth = useAuth();
  const order = useSelector((state: RootState) => state.order);
  const shopCart = useSelector((state: RootState) => state.shoppingCart);
  const dispatch = useDispatch();
  const addressesModal = useDisclosure();
  const navigate = useNavigate();

  const totalAmount = useMemo(() => {
    return calculateShopCartSubTotal(items);
  }, [items]);

  const ordersMutation = useOrdersMutation({
    onSuccess: (response) => {
      if (response.status === true) {
        toast.success();
        eraseShopCart();
        navigate(`${Routes.orders}/${response.data?.uid}`);

        return null;
      }

      toast.error();
    },
    onError: () => {
      toast.error();
    },
  });

  const eraseShopCart = () => {
    const action: ActionProps<ShoppingCartActions> = {
      type: ShoppingCartActions.REMOVE_ALL,
    };
    dispatch(action);
  };

  const handleAddressSelection = (address: IAddress) => {
    const action: ActionProps<OrderActions> = {
      type: OrderActions.ADD_ADDRESS,
      value: address,
    };
    dispatch(action);
    addressesModal[1].close();
  };

  const handleSubmit = () => {
    const payLoad: IOrder = {
      addressId: order.address.id,
      items: shopCart.items,
      userPayModeId: 1,
    };
    ordersMutation.mutate(payLoad);
  };

  useEffect(() => {
    if (!auth.isLoggedIn) {
      window.location.href = `${Routes.auth}/login`;
    }
  }, []);

  return (
    <CheckoutUI
      order={{ ...order, ...{ items: items } }}
      totalAmount={totalAmount}
      isLoading={ordersMutation.isLoading}
      onSubmit={handleSubmit}
      onAddressSelection={handleAddressSelection}
      addressesModal={addressesModal}
    />
  );
}

export default CheckoutScreen;
