import { Flex, Box, Stack, Radio, Text } from "@mantine/core";
import { IconBrandCashapp } from "@tabler/icons-react";

function PayModeItem() {
  return (
    <Flex align={"center"} justify={"space-between"}>
      <Flex gap={5}>
        <Box bg={"white"} py={8} px={10} sx={{ borderRadius: 15 }}>
          <IconBrandCashapp />
        </Box>
        <Stack spacing={4}>
          <Text size={"xs"} fw={"bold"}>
            Paiement Cash
          </Text>
          <Text size={"xs"} fw={"bold"} color="gray">
            Paiement à la livraison
          </Text>
        </Stack>
      </Flex>
      <Radio value="react" checked />
    </Flex>
  );
}

export default PayModeItem;
