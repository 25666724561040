import {
  Stack,
  Flex,
  Group,
  Divider,
  Text,
  useMantineTheme,
  Button,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useCalculateShopCartSubTotal } from "../../hooks/shoppingCart";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";
import { useMemo } from "react";

function CartResume() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const shoppingCartItems = useSelector(
    (state: RootState) => state.shoppingCart.items
  );
  const calculateShopCartSubTotal = useCalculateShopCartSubTotal();
  const toCurrencyFormat = useCurrencyFormat();
  const subTotal = useMemo(() => {
    return calculateShopCartSubTotal(shoppingCartItems);
  }, [shoppingCartItems]);

  return (
    <Stack>
      <Flex justify={"space-between"}>
        <Text fw={"bold"} color="gray">
          Sous Total
        </Text>
        <Group spacing={1}>
          <Text color={theme.primaryColor} fw={"bold"}>
            CDF
          </Text>
          <Text fw={"bold"}>{toCurrencyFormat(subTotal, '')}</Text>
        </Group>
      </Flex>
      <Flex justify={"space-between"}>
        <Text fw={"bold"} color="gray">
          Frais de livraison
        </Text>
        <Group spacing={1}>
          <Text color={theme.primaryColor} fw={"bold"}>
            CDF
          </Text>
          <Text fw={"bold"}>0.00</Text>
        </Group>
      </Flex>
      <Divider my="sm" variant="dotted" size="sm" />
      <Flex justify={"space-between"}>
        <Text fw={"bold"} color="gray">
          Total
        </Text>
        <Group spacing={1}>
          <Text color={theme.primaryColor} fw={"bold"}>
            CDF
          </Text>
          <Text fw={"bold"}>{toCurrencyFormat(subTotal, '')}</Text>
        </Group>
      </Flex>
      <Button
        size="md"
        onClick={() => navigate(Routes.checkout)}
        disabled={shoppingCartItems.length === 0}
      >
        Continuer
      </Button>
    </Stack>
  );
}

export default CartResume;
