import { api } from "../../../api/api";
import { endPoints } from "../../../api/endPoints";
import {
  IPaginationQueryParams,
  IQueryResults,
  PostPutResponse,
} from "../../../api/types";
import { IItemCategory } from "../types";

export const categoriesService = {
  create: (payLoad: IItemCategory): Promise<PostPutResponse<IItemCategory>> =>
    api.post({
      endPoint: endPoints.itemCategories,
      data: payLoad,
      contentType: "multipart/form-data",
    }),
  findAll: (params?: IPaginationQueryParams): Promise<IQueryResults<IItemCategory>> =>
    api.get({ endPoint: endPoints.itemCategories, params: params }),
  find: (uid: string): Promise<IItemCategory> =>
    api.get({ endPoint: `${endPoints.itemCategories}/${uid}` }),
  update: (
    payLoad: IItemCategory,
    uid: string
  ): Promise<PostPutResponse<IItemCategory>> =>
    api.post({
      endPoint: `${endPoints.itemCategories}/${uid}`,
      data: payLoad,
      method: "PUT",
      contentType: "multipart/form-data"
    }),
  delete: (uid: string): Promise<PostPutResponse<IItemCategory>> =>
    api.get({
      endPoint: `${endPoints.itemCategories}/${uid}`,
      method: "DELETE",
    }),
};
