import { ActionIcon, Anchor, Flex, Group } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Text } from "@chakra-ui/react";

interface Props {
  title: string;
  route?: string;
}

function SectionHeader({ title, route = "#" }: Props) {
  const navigate = useNavigate();

  return (
    <Flex justify={"space-between"}>
      <Text size={"lg"} fontWeight={"bold"}>
        {title}
      </Text>
      <Group>
        <Anchor onClick={() => navigate(`${route}`)} color="gray" size={"xs"}>
          Tout voir
        </Anchor>
        <ActionIcon
          onClick={() => navigate(`${route}`)}
          bg={"#f1f1f3"}
          radius={"lg"}
        >
          <IconChevronRight />
        </ActionIcon>
      </Group>
    </Flex>
  );
}

export default SectionHeader;
