import { Stack, Flex, Title, Box, Button, Text, Badge } from "@mantine/core";
import InputQuantity from "../../../../components/InputQuantity";
import ShareLinksModal from "../../../../components/ShareLinksModal";
import ItemColors, { ItemColorsProps } from "../ItemColors";
import ItemImages, { ItemImageProps } from "../ItemImages";
import ItemSizes, { ItemSizesProps } from "../ItemSizes";
import Header, { HeaderProps } from "../Header";
import { IItem } from "../../types";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";

type Props = {
  item: IItem;
  onQuantityChange: (value: number | "") => void;
  onCartAdd: () => void;
} & HeaderProps &
  ItemImageProps &
  ItemColorsProps &
  ItemSizesProps;

function Details({
  mainImage,
  socialShareModal,
  isFavorite,
  onFavorite,
  images,
  onImageClick,
  item,
  onQuantityChange,
  cartItem,
  onSizeChange,
  onColorChange,
  onCartAdd,
}: Props) {
  const toCurrencyFormat = useCurrencyFormat();

  return (
    <>
      <Stack>
        <Header
          mainImage={mainImage}
          socialShareModal={socialShareModal}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
        />
        <ItemImages
          images={images || []}
          mainImage={mainImage}
          onImageClick={onImageClick}
        />
        <Stack mt={5} px={10}>
          <Flex justify={"space-between"}>
            <Title size={20}>{item?.name}</Title>
            <Title size={20}>
              {toCurrencyFormat(item?.salePrice || 0, "CDF")}
            </Title>
          </Flex>
          <Flex justify={"space-between"}>
            <Text size={"xs"} color="gray">
              Catégorie
            </Text>
            <Badge size="xs">{item.category?.name}</Badge>
          </Flex>
          <Stack align="center">
            <Box w={150}>
              <InputQuantity
                min={1}
                max={1000}
                onChange={(value) => onQuantityChange(value)}
              />
            </Box>
            <Text size={"xs"} color="gray" maw={"90%"}>
              {item?.description}
            </Text>
            <ItemSizes
              sizes={item?.sizes || []}
              cartItem={cartItem}
              onSizeChange={onSizeChange}
            />
            <ItemColors
              colors={item?.colors || []}
              cartItem={cartItem}
              onColorChange={onColorChange}
            />
          </Stack>
        </Stack>
        <Button onClick={onCartAdd} mx={10}>
          Ajouter au panier
        </Button>
      </Stack>
      <ShareLinksModal
        url={window.location.href}
        opened={socialShareModal[0]}
        onClose={socialShareModal[1].close}
      />
    </>
  );
}

export default Details;
