import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IPaginationQueryParams } from "../../../api/types";
import { endPoints } from "../../../api/endPoints";
import { IOrder } from "../types";
import { IMutationProps } from "../../types";
import { ordersService } from "../services/orders";
import { IItem } from "../../items/types";

export const useOrders = (params?: IPaginationQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [endPoints.orders, params],
    queryFn: () => ordersService.findAll(params),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: true,
  });

  return {
    data: data?.data || [],
    meta: data?.meta || { current_page: 0, per_page: 0, total: 0 },
    errorResponse: data?.meta === undefined && !rest.isLoading,
    ...rest,
  };
};

export const useOrder = (uid: string) => {
  const { data, ...rest } = useQuery({
    queryKey: [`${endPoints.orders}${uid}`],
    queryFn: () => ordersService.find(uid),
  });

  return {
    data: data,
    errorResponse: data?.id === undefined && !rest.isLoading,
    ...rest,
  };
};

export const useOrdersMutation = ({
  onSuccess,
  onError,
  model,
}: IMutationProps<IOrder>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: IOrder) =>
      model
        ? ordersService.update(payload, `${model.uid}`)
        : ordersService.create(payload),
    onSuccess: (response) => {
      queryClient.invalidateQueries([endPoints.orders]);
      onSuccess?.(response);
    },
    onError: (errors) => {
      onError?.(errors);
    },
  });
};

export const useParseOrder = (order?: IOrder) => {
  if (order?.orderItems) {
    const items = order.orderItems.map((orderItem) => {
      const item: IItem = {
        ...orderItem.item,
        ...{
          quantity: orderItem.quantity,
          salePrice: orderItem.price,
          size: orderItem.size,
          color: orderItem.color,
        },
      };

      return item;
    });
    const data: IOrder = {
      ...order,
      ...{ items: items },
    };

    return data;
  }

  return order;
};
