import { ActionIcon, Group, Table, Text, useMantineTheme } from "@mantine/core";
import { Image } from "@chakra-ui/react";
import { IItem } from "../../../../items/types";
import { useCurrencyFormat } from "../../../../../hooks/currencyFormat";
import { useParseItem } from "../../../../items/hooks/items";

interface Props {
  items: IItem[];
}

function OrderItemsTable({ items }: Props) {
  const theme = useMantineTheme();
  const toCurrencyFormat = useCurrencyFormat();
  const parseItem = useParseItem();

  return (
    <Table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantité</th>
          <th>Prix Unitaire</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item) => {
          item = parseItem(item);
          return (
            <tr key={item.id}>
              <td>
                <Group>
                  <Image
                    src={`${process.env.REACT_APP_FILES_URL}/items/${
                      item.images ? item.images[0].cover : ""
                    }`}
                    height={70}
                    width={70}
                    fit="contain"
                  />
                  <Text>{item.name}</Text>
                  <Text
                    size={"xs"}
                    color={theme.colorScheme === "light" ? "gray" : "#9E9E9E"}
                  >
                    Taille &amp; Couleur:
                  </Text>
                  <ActionIcon bg={item.color}>
                    <Text>{item.size}</Text>
                  </ActionIcon>
                </Group>
              </td>
              <td>{item.quantity}</td>
              <td>{toCurrencyFormat(item.salePrice, "")} CDF</td>
              <td>
                {toCurrencyFormat(item.salePrice * item.quantity, "")} CDF
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default OrderItemsTable;
