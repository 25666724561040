import {
  createStyles,
  Button,
  getStylesRef,
  rem,
  Stack,
  Title,
  Flex,
  Box as MBox,
  useMantineTheme,
} from "@mantine/core";
import { AspectRatio, Box, Image } from "@chakra-ui/react";
import { Carousel as MCarousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { usePromotions } from "../../../items/hooks/promotions";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";
import { useRef } from "react";

const useStyles = createStyles((theme) => ({
  price: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  carousel: {
    "&:hover": {
      [`& .${getStylesRef("carouselControls")}`]: {
        opacity: 1,
      },
    },
  },

  carouselControls: {
    ref: getStylesRef("carouselControls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  carouselIndicator: {
    width: rem(4),
    height: rem(4),
    transition: "width 250ms ease",

    "&[data-active]": {
      width: rem(16),
    },
  },
}));

export function Carousel() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const promotionsQuery = usePromotions();
  const navigate = useNavigate();
  const autoplay = useRef(Autoplay({ delay: 2500 }));

  const slides = promotionsQuery.data.map((promotion) => (
    <MCarousel.Slide key={promotion.uid}>
      <MBox bg={theme.primaryColor} h={200} p={15}>
        <Flex justify={"space-between"}>
          <Stack>
            <Title color="white" size={25} maw={200}>
              {promotion.notes}
            </Title>
            <Button
              onClick={() =>
                navigate(`${Routes.itemsCategories}/${promotion.category?.uid}`)
              }
              variant="white"
              size="xs"
              maw={150}
              radius={"lg"}
            >
              Achetez maintenant
            </Button>
          </Stack>
          <Box h={180}>
            <AspectRatio ratio={720 / 1080} w={100}>
              <Image
                src={`${process.env.REACT_APP_FILES_URL}/promotions/${promotion.cover}`}
                alt="Panda"
                width={"100%"}
                height={"100%"}
              />
            </AspectRatio>
          </Box>
        </Flex>
      </MBox>
    </MCarousel.Slide>
  ));

  return (
    <MCarousel
      withIndicators
      withControls
      loop
      plugins={[autoplay.current]}
      classNames={{
        root: classes.carousel,
        controls: classes.carouselControls,
        indicator: classes.carouselIndicator,
      }}
    >
      {slides}
    </MCarousel>
  );
}
