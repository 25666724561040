import { Group } from "@mantine/core";
import { IItem } from "../../types";
import ColorSelect from "../../../../components/ColorSelect";

export interface ItemColorsProps {
  colors: string[];
  cartItem: IItem;
  onColorChange: (value: string) => void;
}

function ItemColors({ colors, cartItem, onColorChange }: ItemColorsProps) {
  return (
    <Group>
      {colors?.map((color) => (
        <ColorSelect
          key={color}
          color={color}
          selected={cartItem.color === color}
          onClick={() => onColorChange(color)}
        />
      ))}
    </Group>
  );
}

export default ItemColors;
