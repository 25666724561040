import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import { shoppingCartReducer } from "./reducers/shoppingCartReducer";
import { itemsReducer } from "./reducers/itemsReducer";
import { orderReducer } from "./reducers/order";

const persistConfig = {
  key: "root",
  storage,
};


export const Store = configureStore({
  reducer: {
    shoppingCart: persistReducer(persistConfig, shoppingCartReducer),
    order: orderReducer,
    itemsReducer: persistReducer(persistConfig, itemsReducer),
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});

export const persistor = persistStore(Store);
export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
