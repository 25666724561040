import { Flex, Box, useMantineTheme } from "@mantine/core";
import { Image } from "@chakra-ui/react";
import { IItemImage } from "../../types";

export interface ItemImageProps {
  images: IItemImage[];
  mainImage: string;
  onImageClick: (src: string) => void;
}

function ItemImages({ images, mainImage, onImageClick }: ItemImageProps) {
  const theme = useMantineTheme();
  return (
    <Flex px={10}>
      {images?.map((image) => (
        <Box
          key={image.uid}
          w={90}
          h={60}
          bg={"#f1f1f3"}
          p={5}
          sx={{
            borderRadius: 10,
            borderWidth: 3,
            borderColor:
              image.cover === mainImage ? theme.primaryColor : "white",
            borderStyle: "solid",
          }}
          onClick={() => onImageClick(image.cover)}
        >
          <Image
            src={`${process.env.REACT_APP_FILES_URL}/items/${image.cover}`}
            style={{
              objectFit: "contain",
            }}
            width={"100%"}
            height={"100%"}
          />
        </Box>
      ))}
    </Flex>
  );
}

export default ItemImages;
