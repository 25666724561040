export const Routes = {
    home: "/",
    auth: "/auth",
    notFound: "/404",
    denied: "/401",
    userProfile: "/profile",
    items: "/items",
    categories: "/categories",
    itemsCategories: "/item-categories",
    topItems: "/top-items",
    shoppingCart: "/cart",
    checkout: "/checkout",
    orders: "/orders",
};

export const excludeInAppLayoutRoutes = [
    `${Routes.auth}/login`,
    `${Routes.auth}/register`,
    Routes.notFound
];

  