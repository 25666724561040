import {
  Group,
  Stack,
  Text,
  ActionIcon,
  Grid,
  MediaQuery,
} from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import CartItems from "../../components/CartItems";
import CartResume from "../../components/CartResume";
import { useNavigate } from "react-router-dom";

function ShoppingCartScreen() {
  const navigate = useNavigate();
  return (
    <Stack px={15} py={5}>
      <Group>
        <ActionIcon bg={"#f1f1f3"} radius={"lg"} onClick={() => navigate(-1)}>
          <IconChevronLeft />
        </ActionIcon>
        <Text size={"xl"} fw={"bold"}>
          Votre Panier
        </Text>
      </Group>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Stack>
          <CartItems />
          <CartResume />
        </Stack>
      </MediaQuery>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Grid grow>
          <Grid.Col span={6}>
            <CartItems />
          </Grid.Col>
          <Grid.Col span={6}>
            <CartResume />
          </Grid.Col>
        </Grid>
      </MediaQuery>
    </Stack>
  );
}

export default ShoppingCartScreen;
