import { Routes, Route, Navigate } from "react-router-dom";
import AuthScreen from "../features/auth/screens/AuthScreen";
import { useAuth } from "../features/auth/hooks/auth";
import RouteGuard from "./RouteGuard";
import { PageNotFoundScreen } from "../features/errors/screens/PageNotFoundScreen";
import { Routes as appRoutes } from "./routes";
import { AccessDeniedScreen } from "../features/errors/screens/AccessDeniedScreen";
import HomeScreen from "../features/home/screens/HomeScreen";
import ItemDetailsScreen from "../features/items/screens/ItemDetailsScreen";
import ItemsScreen from "../features/items/screens/ItemsScreen";
import ShoppingCartScreen from "../features/shoppingCart/screens/ShoppingCartScreen";
import CheckoutScreen from "../features/checkout/screens/CheckoutScreen";
import OrderDetailsScreen from "../features/orders/screens/OrderDetailsScreen";
import UserDetailsScreen from "../features/auth/screens/UserDetailsScreen";

export default function MainNavigator() {
  const auth = useAuth();

  return (
    <Routes>
      <Route path={appRoutes.home} element={<HomeScreen />} />
      <Route path={`${appRoutes.auth}/:type`} element={<AuthScreen />} />
      <Route path={appRoutes.notFound} element={<PageNotFoundScreen />} />
      <Route path={appRoutes.denied} element={<AccessDeniedScreen />} />
      <Route
        path={`${appRoutes.itemsCategories}/:uid`}
        element={<ItemsScreen />}
      />
      <Route path={appRoutes.items} element={<ItemsScreen />} />
      <Route path={`${appRoutes.items}/:uid`} element={<ItemDetailsScreen />} />
      <Route path={appRoutes.shoppingCart} element={<ShoppingCartScreen />} />
      <Route path={appRoutes.checkout} element={<CheckoutScreen />} />
      <Route
        path={`${appRoutes.orders}/:uid`}
        element={<OrderDetailsScreen />}
      />
      <Route
        path={appRoutes.userProfile}
        element={
          <RouteGuard
            isRouteAccessible={auth.isLoggedIn}
            redirectRoute={appRoutes.denied}
          />
        }
      >
        <Route path={appRoutes.userProfile} element={<UserDetailsScreen />} />
      </Route>

      <Route path="*" element={<Navigate to={appRoutes.notFound} />} />
    </Routes>
  );
}
