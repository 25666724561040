import { IAddress } from "../../../features/addresses/types";
import { IUSerPayMode } from "../../../features/payModes/types";
import { ActionProps } from "../../types";
import { OrderActions } from "./actions";

interface Props {
  address: IAddress;
  payMethod: IUSerPayMode;
}

const initialState: Props = {
  address: {
    street: "addresse",
    reference: "aucune",
    district: "choisie",
    city: { name: "Choisissez une adresse" },
  },
  payMethod: { payModeId: "1", number: "0", userId: "0" },
};

export function orderReducer(
  state = initialState,
  action: ActionProps<OrderActions>
) {
  let nextState: typeof initialState;

  switch (action.type) {
    case OrderActions.ADD_ADDRESS:
      nextState = {
        ...state,
        address: { ...state.address, ...action.value },
      };

      return nextState;

    default:
      return state;
  }
}
