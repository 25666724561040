import { Flex, Box, Text, Skeleton, Group } from "@mantine/core";
import { Image } from "@chakra-ui/react";
import { Routes } from "../../../../navigation/routes";
import { useCategories } from "../../hooks/categories";
import { Carousel } from "@mantine/carousel";
import { Link } from "react-router-dom";

interface Props {
  categoryId?: number | null;
}

function CategoriesList({ categoryId = 0 }: Props) {
  const categoriesQuery = useCategories({
    key: "category_id",
    value: categoryId,
  });

  if (categoriesQuery.isLoading) {
    return (
      <Group>
        {[1, 2, 3].map((value) => (
          <Skeleton key={value} w={90} h={90} />
        ))}
      </Group>
    );
  }

  return (
    <>
      {categoriesQuery.data.map((category) => (
        <Carousel.Slide key={category.id}>
          <Box
            h={90}
            sx={{ position: "relative", borderRadius: 10, overflow: "hidden" }}
          >
            <Link
              to={`${Routes.itemsCategories}/${category.uid}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Flex
                direction="column"
                gap="1rem"
                justify="space-between"
                bg="white"
                color={"black"}
                align={"center"}
              >
                <Box
                  sx={{ position: "absolute" }}
                  w={120}
                  h={120}
                  bg={"rgba(0, 0, 0, 0.3)"}
                />
                <Box w={120} h={120} p={0} m={0}>
                  <Image
                    src={`${process.env.REACT_APP_FILES_URL}/categories/${category.cover}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Text
                  mt={5}
                  sx={{ zIndex: 2000, position: "absolute", bottom: 0 }}
                  color="white"
                  fw={"bold"}
                >
                  {category.name}
                </Text>
              </Flex>
            </Link>
          </Box>
        </Carousel.Slide>
      ))}
    </>
  );
}

export default CategoriesList;
