import { Stack } from "@chakra-ui/react";
import { useItems } from "../../hooks/items";
import { ItemListCarousel } from "../ItemListCarousel";
import SectionHeader from "../../../../components/SectionHeader";
import { Routes } from "../../../../navigation/routes";

function TopItemsList() {
  const itemsQuery = useItems({ per_page: 10, key: "is_top", value: 1 });

  if (!itemsQuery.isLoading && itemsQuery.data.length === 0) {
    return <></>;
  }

  return (
    <Stack>
      <SectionHeader
        title="Articles recommandés"
        route={`${Routes.itemsCategories}/tops`}
      />
      <ItemListCarousel
        items={itemsQuery.data}
        isLoading={itemsQuery.isLoading || itemsQuery.isFetching}
      />
    </Stack>
  );
}

export default TopItemsList;
