import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IPaginationQueryParams } from "../../../api/types";
import { endPoints } from "../../../api/endPoints";
import { IItemPromotion } from "../types";
import { IMutationProps } from "../../types";
import { promotionsService } from "../services/promotions";

export const usePromotions = (params?: IPaginationQueryParams) => {
  const { data, ...rest } = useQuery(
    {
      queryKey: [endPoints.promotions, params], 
      queryFn: () => promotionsService.findAll(params),
      keepPreviousData: true,
      staleTime: Infinity,
      enabled: true
    },
  );

  return {
    data: data?.data || [],
    meta: data?.meta || {},
    errorResponse: data?.meta === undefined && !rest.isLoading,
    ...rest
  };
};

export const usePromotion = (uid: string) => {
  const { data, ...rest } = useQuery(
    {
      queryKey: [`${endPoints.promotions}${uid}`], 
      queryFn: () => promotionsService.find(uid),
    },
  );

  return {
    data: data,
    errorResponse: data?.id === undefined && !rest.isLoading,
    ...rest
  };
};

export const usePromotionsMutation = ({
    onSuccess,
    onError,
    model
  }: IMutationProps<IItemPromotion>) => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: (payload: IItemPromotion) =>
        model ? promotionsService.update(payload, `${model.uid}`) : promotionsService.create(payload),
      onSuccess: (response) => {
        queryClient.invalidateQueries([endPoints.promotions]);
        onSuccess?.(response);
      },
      onError: (errors) => {
        onError?.(errors);
      },
    });
};

export const usePromotionDelete = ({
    onSuccess,
    onError
  }: IMutationProps<IItemPromotion>) => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: (uid: string) => promotionsService.delete(uid),
      onSuccess: (response) => {
        queryClient.invalidateQueries([endPoints.promotions]);
        onSuccess?.(response);
      },
      onError: (errors) => {
        onError?.(errors);
      },
    });
};
