export const useCurrencyFormat = () => {
  const currencyFormat = (amount: number, currency: string = "$") => {
    const formattedValue = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    return `${currency} ${formattedValue}`;
  };

  return currencyFormat;
};
