import { Anchor, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";

interface Props {
  mode?: "login" | "register";
}

function LoginRegisterLink({ mode = 'login' }: Props) {
  return (
    <Text ta="center" mt="md">
      Vous {mode === "login" ? "n’avez pas de" : "avez un"} compte?{" "}
      <Anchor<"a">
        href="#"
        weight={700}
        onClick={(event) => event.preventDefault()}
      >
        <Link to={`${Routes.auth}/${mode === "login" ? "register" : "login"}`}>
          {mode === 'login' ? 'Inscription' : 'Connexion'}
        </Link>
      </Anchor>
    </Text>
  );
}

export default LoginRegisterLink;
