import { api } from "../../../api/api";
import { endPoints } from "../../../api/endPoints";
import { IPaginationQueryParams, IQueryResults, PostPutResponse } from "../../../api/types";
import { IOrder } from "../types";

export const ordersService = {
  create: (payLoad: IOrder): Promise<PostPutResponse<IOrder>> =>
    api.post({ endPoint: endPoints.orders, data: payLoad }),
  findAll: (params?: IPaginationQueryParams): Promise<IQueryResults<IOrder>> =>
    api.get({ endPoint: endPoints.orders, params: params }),
  find: (uid: string): Promise<IOrder> =>
    api.get({ endPoint: `${endPoints.orders}/${uid}` }),
  update: (payLoad: IOrder, uid: string): Promise<PostPutResponse<IOrder>> =>
    api.post({ endPoint: `${endPoints.orders}/${uid}`, data: payLoad, method: "PUT" }),
  delete: (uid: string): Promise<PostPutResponse<IOrder>> =>
    api.get({ endPoint: `${endPoints.orders}/${uid}`, method: "DELETE" }),
};