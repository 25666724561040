import { Box, ActionIcon, Group, useMantineTheme } from "@mantine/core";
import { Image } from "@chakra-ui/react";
import {
  IconChevronLeft,
  IconShare,
  IconHeartFilled,
  IconHeart,
} from "@tabler/icons-react";
import { ModalProps } from "../../../types";
import { useNavigate } from "react-router-dom";

export interface HeaderProps {
  mainImage: string;
  socialShareModal: ModalProps;
  isFavorite: boolean;
  onFavorite: (action: "add" | "remove") => void;
}

function Header({
  mainImage,
  socialShareModal,
  isFavorite,
  onFavorite,
}: HeaderProps) {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <Box h={250} bg={"#f1f1f3"} px={5} sx={{ position: "relative" }}>
      <ActionIcon
        bg={"white"}
        size={"lg"}
        radius={"lg"}
        sx={{ position: "absolute", top: 0 }}
        onClick={() => navigate(-1)}
      >
        <IconChevronLeft />
      </ActionIcon>
      <Group spacing={3} sx={{ position: "absolute", top: 0, right: 5 }}>
        <ActionIcon
          onClick={socialShareModal[1].open}
          bg={"white"}
          size={"lg"}
          radius={"lg"}
        >
          <IconShare />
        </ActionIcon>
        <ActionIcon bg={"white"} size={"lg"} radius={"lg"}>
          {isFavorite ? (
            <IconHeartFilled
              style={{ color: theme.primaryColor }}
              onClick={() => onFavorite("remove")}
            />
          ) : (
            <IconHeart
              color={theme.primaryColor}
              onClick={() => onFavorite("add")}
            />
          )}
        </ActionIcon>
      </Group>
      <Image
        src={`${process.env.REACT_APP_FILES_URL}/items/${mainImage}`}
        style={{ objectFit: "contain" }}
        width={"100%"}
        height={"100%"}
      />
    </Box>
  );
}

export default Header;
