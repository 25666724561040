import { MediaQuery, Grid } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  span: { sm: number; lg: number };
}

function GridMediaQuery({ children, span }: Props) {
  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Grid.Col span={span.lg}>{children}</Grid.Col>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Grid.Col span={span.sm}>{children}</Grid.Col>
      </MediaQuery>
    </>
  );
}

export default GridMediaQuery;
