import { ActionIcon, MantineNumberSize } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

interface Props {
  color?: string;
  selected?: boolean;
  onClick?: (color: string) => void;
  size?: MantineNumberSize;
}

function ColorSelect({
  color = "gray",
  selected = false,
  onClick,
  size = "md",
}: Props) {
  const isLightColor = lightColors.includes(color);
  return (
    <ActionIcon
      onClick={() => onClick?.(color)}
      bg={color}
      radius={"xl"}
      sx={{ borderWidth: 1, borderColor: isLightColor ? "black" : "none" }}
      size={size}
    >
      {selected && <IconCheck color={isLightColor ? "black" : "white"} />}
    </ActionIcon>
  );
}

const lightColors = [
  "white",
  "#fff",
  "#ffffff",
  "#fafafa",
  "#f5f5f5",
  "#eeeeee",
  "#efebe9",
];

export default ColorSelect;
