import { Box, Button, Group, Modal } from "@mantine/core";
import Addresses from "../Addresses";
import { IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import AddressFormModal from "./AddressFormModal";
import { IAddress } from "../../types";

interface Props {
  opened: boolean;
  onClose: () => void;
  onItemSelect?: (address: IAddress) => void
}

function AddressesModal({ opened, onClose, onItemSelect }: Props) {
  const addressFormModal = useDisclosure();
  return (
    <>
      <Modal opened={opened} onClose={onClose} title="Vos Adresses" size={"lg"}>
        <Group position="right">
          <Button onClick={addressFormModal[1].open} leftIcon={<IconPlus />}>
            Ajouter une adresse
          </Button>
        </Group>
        <Box my={10} py={20}>
          <Addresses onItemSelect={onItemSelect} />
        </Box>
      </Modal>
      <AddressFormModal
        opened={addressFormModal[0]}
        onClose={addressFormModal[1].close}
        centered={false}
      />
    </>
  );
}

export default AddressesModal;
