import { useState } from "react";
import { Avatar, UnstyledButton, Group, Menu, ActionIcon } from "@mantine/core";
import { IconLogout, IconSettings, IconLogin } from "@tabler/icons-react";
import { styles } from "./styles";
import { useAuth } from "../../hooks/auth";
import { Routes } from "../../../../navigation/routes";
import { useNavigate } from "react-router-dom";

export default function UserAvatar() {
  const { classes, theme, cx } = styles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { user, logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(Routes.home);
  };

  const handleLogin = () => (window.location.href = `${Routes.auth}/login`);

  if (!isLoggedIn) {
    return (
      <ActionIcon bg={"gray"} onClick={handleLogin}>
        <IconLogin color="white" />
      </ActionIcon>
    );
  }

  return (
    <>
      {isLoggedIn && (
        <Menu
          position="bottom-end"
          transitionProps={{ transition: "pop-top-right" }}
          onClose={() => setUserMenuOpened(false)}
          onOpen={() => setUserMenuOpened(true)}
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton
              className={cx(classes.user, {
                [classes.userActive]: userMenuOpened,
              })}
            >
              <Group spacing={7}>
                <Avatar
                  alt={user?.name}
                  radius="xl"
                  size={30}
                  color={theme.primaryColor}
                >
                  {user?.name?.charAt(0)}
                </Avatar>
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => navigate("/profile")}
              icon={<IconSettings size="0.9rem" stroke={1.5} />}
            >
              Mon Profile
            </Menu.Item>
            <Menu.Item
              onClick={handleLogout}
              icon={<IconLogout size="0.9rem" stroke={1.5} />}
            >
              Se déconnecter
            </Menu.Item>
            <Menu.Divider />
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
}
