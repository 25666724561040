import { Card, Stack } from "@chakra-ui/react";
import { Button, Group, Text, rem } from "@mantine/core";
import { IconBadgeFilled, IconShare } from "@tabler/icons-react";
import { LatestItem, TopCategories } from "../../../features/items/components";
import ShareLinksModal from "../../../components/ShareLinksModal";
import { useDisclosure } from "@mantine/hooks";

export function Aside() {
  const socialShareModal = useDisclosure();

  return (
    <>
      <Stack
        w={{ md: 320 }}
        mx={3}
        display={{ base: "none", md: "flex", lg: "flex", xl: "flex" }}
        gap={3}
      >
        <Button leftIcon={<IconShare size={rem(20)} />} onClick={socialShareModal[1].open}>
          Partager le lien
        </Button>
        <Card borderWidth={0.2} borderRadius={"lg"} p={2}>
          <Stack>
            <Group spacing={4}>
              <IconBadgeFilled style={{ color: "orange" }} />
              <Text>Achetez en toute confiance</Text>
            </Group>
            <Group grow align="center">
              <Text style={{ flexWrap: "wrap" }} size={"xs"}>
                Garantie client SecHand, Obtenez un remboursement si vous ne
                recevez pas l'objet que vous avez commandé.
              </Text>
            </Group>
          </Stack>
        </Card>
        <LatestItem />
        <TopCategories />
      </Stack>
      <ShareLinksModal
        url={window.location.href}
        opened={socialShareModal[0]}
        onClose={socialShareModal[1].close}
      />
    </>
  );
}
