import { Flex, Box, Stack, ActionIcon, Text } from "@mantine/core";
import { IconMapPin, IconChevronRight } from "@tabler/icons-react";
import { IAddress } from "../types";

interface Props{
    address: IAddress;
    onClick?: (address: IAddress) => void;
}

function Address({ address, onClick }: Props) {
  return (
    <Flex onClick={() => onClick?.(address)} align={"center"} justify={"space-between"}>
      <Flex gap={5}>
        <Box bg={"white"} py={8} px={10} sx={{ borderRadius: 15 }}>
          <IconMapPin />
        </Box>
        <Stack spacing={4}>
          <Text size={"xs"} fw={"bold"}>
            {`${address.reference}, ${address.street}, ${address.district}`}
          </Text>
          <Text size={"xs"} fw={"bold"} color="gray">
            {address.city?.name}
          </Text>
        </Stack>
      </Flex>
      <ActionIcon onClick={() => onClick?.(address)}>
        <IconChevronRight />
      </ActionIcon>
    </Flex>
  );
}

export default Address;
