import { Stack } from "@mantine/core";
import { useAddresses } from "../hooks/addresses";
import Address from "./Address";
import { IAddress } from "../types";

interface Props {
  onItemSelect?: (address: IAddress) => void;
}

function Addresses({ onItemSelect }: Props) {
  const addressesQuery = useAddresses();

  return (
    <Stack>
      {addressesQuery.data.map((address) => (
        <Address key={address.id} address={address} onClick={onItemSelect} />
      ))}
    </Stack>
  );
}

export default Addresses;
