import { api } from "../../../api/api";
import { endPoints } from "../../../api/endPoints";
import {
  IPaginationQueryParams,
  IQueryResults,
  PostPutResponse,
} from "../../../api/types";
import { IItem } from "../types";

export const itemsService = {
  create: (payLoad: IItem): Promise<PostPutResponse<IItem>> =>
    api.post({
      endPoint: endPoints.items,
      data: payLoad,
      contentType: "multipart/form-data",
    }),
  findAll: (params?: IPaginationQueryParams): Promise<IQueryResults<IItem>> =>
    api.get({ endPoint: endPoints.items, params: params }),
  find: (uid: string): Promise<IItem> =>
    api.get({ endPoint: `${endPoints.items}/${uid}` }),
  update: (payLoad: IItem, uid: string): Promise<PostPutResponse<IItem>> =>
    api.post({
      endPoint: `${endPoints.items}/${uid}`,
      data: payLoad,
      method: "PUT",
      contentType: "multipart/form-data"
    }),
  delete: (uid: string): Promise<PostPutResponse<IItem>> =>
    api.get({ endPoint: `${endPoints.items}/${uid}`, method: "DELETE" }),
};
