import { useMantineTheme } from "@mantine/core";
import {
  TablerIconsProps,
  IconCoin,
  IconBrandCashapp,
  IconTruckDelivery,
} from "@tabler/icons-react";
import { Flex, Card, Text, Icon } from "@chakra-ui/react";

export function Banner() {
  const theme = useMantineTheme();

  return (
    <Card w={"full"} borderWidth={0.5} borderRadius={"lg"} px={2} py={4}>
      <Flex justify={"space-between"}>
        {data.map((value) => (
          <Flex
            key={value.title}
            align={"center"}
            gap={1}
            direction={{ base: "column", md: "row", xl: "row" }}
          >
            <Icon color={theme.primaryColor} as={value.icon} boxSize={8} />
            <Text
              fontWeight={'bold'}
              textAlign={{ base: "center", md: "start", xl: "start" }}
              fontSize={'sm'}
              color={'gray'}
            >
              {value.title}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}

const data: {
  title: string;
  icon: (props: TablerIconsProps) => JSX.Element;
}[] = [
  {
    title: "Paiement à la livraison",
    icon: IconBrandCashapp,
  },
  {
    title: "Garantie de remboursement",
    icon: IconCoin,
  },
  {
    title: "Livraison gratuite(L'shi)",
    icon: IconTruckDelivery,
  },
];
