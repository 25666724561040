import { Grid, Pagination, Stack } from "@mantine/core";
import Item from "../Item";
import { useItems } from "../../hooks/items";
import ItemSkeleton from "../ItemSkeleton";
import GridMediaQuery from "../../../../components/GridMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import NoResults from "../../../../components/NoResults";
import { ItemActions } from "../../../../store/reducers/itemsReducer/actions";
import { ActionProps } from "../../../../store/types";
import { useEffect } from "react";

interface Props {
  categoryId?: number;
  keyword?: string | null;
  isFavorites?: boolean;
  keyName?: string;
  keyValue?: string | number | boolean;
}

function Items({
  categoryId,
  keyword,
  isFavorites = false,
  keyName,
  keyValue,
}: Props) {
  const dispacth = useDispatch();
  const activePage = useSelector(
    (state: RootState) => state.itemsReducer.activePage
  );

  const itemsQuery = useItems({
    page: activePage,
    key: keyName || "category_id",
    value: keyValue || categoryId,
    field: "name",
    keyword: keyword,
  });
  const favoriteItems = useSelector(
    (state: RootState) => state.itemsReducer.favorites
  );

  const handlePageChange = (page: number) => {
    const action: ActionProps<ItemActions> = {
      type: ItemActions.SET_ACTIVE_PAGE,
      value: page,
    };
    dispacth(action);
  };

  const { meta } = itemsQuery;
  const pages = Math.ceil((meta?.total || 0) / (meta?.per_page || 1));
  const items = !isFavorites ? itemsQuery.data : favoriteItems;

  useEffect(() => {
    if (itemsQuery.data.length === 0 && !itemsQuery.isLoading) {
      handlePageChange(1);
    }
  }, [itemsQuery.data, itemsQuery.isLoading]);

  if ((itemsQuery.isLoading || itemsQuery.isFetching) && !isFavorites) {
    return (
      <Grid grow>
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <GridMediaQuery key={item} span={{ sm: 6, lg: 3 }}>
            <ItemSkeleton />
          </GridMediaQuery>
        ))}
      </Grid>
    );
  }
  return (
    <Stack>
      <Grid>
        {items.map((item) => (
          <GridMediaQuery key={item.id} span={{ sm: 6, lg: 3 }}>
            <Item item={item} />
          </GridMediaQuery>
        ))}
      </Grid>
      <Pagination
        my={15}
        total={pages}
        value={activePage}
        boundaries={0}
        onChange={(value) => handlePageChange(value)}
        position="center"
        styles={(theme) => ({
          control: {
            "&[data-active]": {
              backgroundImage: theme.primaryColor,
              border: 0,
            },
          },
        })}
      />
      {!itemsQuery.isLoading && items.length === 0 && <NoResults />}
    </Stack>
  );
}

export default Items;
