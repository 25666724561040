import { Box, Flex, Image, Text } from "@mantine/core";

interface Props {
  title?: string;
  description?: string;
  icon?: JSX.Element;
}

function NoResults({ title, description, icon }: Props) {
  return (
    <Box w={"full"}>
      <Flex justify={"center"}>
        <Image
          src="https://cdn.dribbble.com/assets/art-banners/record-d05a64c16f564e98f34e41aec5ec07d5b12eb7ea8fb3a1bb0b12250b558de93b.png"
          w={200}
        />
      </Flex>
      <Text color="#26333b" size={"sm"} ta={"center"}>
        {title || "Désolé, aucun résultat trouvé."}
      </Text>
      {/* <Text fs={"14px"} color="#707885" ta={"center"}>
        {description ||
          "Rien ne correspond à vos termes de recherche. Veuillez réessayer avec d'autres mots-clés."}
      </Text> */}
    </Box>
  );
}

export default NoResults;
