import { Box, Stack, Text, Badge, Group } from "@mantine/core";
import { Flex, Image } from "@chakra-ui/react";
import { IItem } from "../../types";
import { Link } from "react-router-dom";
import { Routes } from "../../../../navigation/routes";
import { useCurrencyFormat } from "../../../../hooks/currencyFormat";
import { Carousel } from "@mantine/carousel";
import { useItemParser } from "../../hooks/items";

interface Props {
  item: IItem;
}

function TopItem({ item }: Props) {
  const toCurrencyFormat = useCurrencyFormat();

  item = useItemParser(item) || item;

  return (
    <Link
      to={`${Routes.items}/${item.uid}`}
      style={{ textDecoration: "none", color: "black" }}
    >
      <Flex gap={3}>
        <Box
          w={120}
          h={120}
          bg={"#f1f1f3"}
          sx={{ borderRadius: 10, overflow: "hidden" }}
        >
          <Image
            src={`${process.env.REACT_APP_FILES_URL}/items/${
              item.images ? item.images[0].cover : ""
            }`}
            style={{ objectFit: "scale-down" }}
            width={"100%"}
            height={"100%"}
            alt={item.name}
          />
        </Box>
        <Stack spacing={0}>
          <Text>{item.name}</Text>
          <Flex align={"center"} justify={"space-between"}>
            <Text size={"xs"} color="gray">
              Tailles:
            </Text>
            <Badge tt={"uppercase"} size="xs">
              {item.sizes?.map((size) => `${size}.`)}
            </Badge>
          </Flex>
          <Text fw={"bold"}>{toCurrencyFormat(item.salePrice, "CDF")}</Text>
        </Stack>
      </Flex>
    </Link>
  );
}

export default TopItem;
