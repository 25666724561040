import { Group, Box, Burger, Indicator, Button } from "@mantine/core";
import { Box as CBox } from "@chakra-ui/react";
import { useDisclosure } from "@mantine/hooks";
import { IconBasket, IconBrandWhatsapp } from "@tabler/icons-react";
import { headerStyles } from "./styles/headerStyles";
import UserAvatar from "../../features/auth/components/avatar/UserAvatar";
import { MobileDrawer } from "./MobileDrawer";
import Logo from "../../components/Logo";
import InputSearch from "../../components/InputSearch";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../navigation/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export function AppHeader() {
  const navigate = useNavigate();
  const shoppingCartItems = useSelector(
    (state: RootState) => state.shoppingCart.items
  );
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes } = headerStyles();

  const handleSearch = (keyword: string) => {
    navigate(`${Routes.items}?keyword=${keyword}`);
  };

  return (
    <CBox>
      <CBox px={2} bg={"#131921"} pb={1}>
        <CBox>
          <Group position="apart" sx={{ height: "100%" }}>
            <Group>
              <Link to={Routes.home}>
                <Logo />
              </Link>
              <CBox display={{ base: "none", md: "block", xl: "block" }}>
                <Group>
                  <a href="whatsapp://send?text=hello&phone=243844303021">
                    <Button radius={"lg"} leftIcon={<IconBrandWhatsapp />}>
                      Contactez-nous
                    </Button>
                  </a>

                  <Box w={{ sm: 300, md: 600, xl: 500 }}>
                    <InputSearch onKeyDown={handleSearch} />
                  </Box>
                </Group>
              </CBox>
            </Group>
            <Group>
              <Link to={Routes.shoppingCart}>
                <Indicator label={shoppingCartItems.length}>
                  <IconBasket color="white" />
                </Indicator>
              </Link>
              <UserAvatar />
              <Burger
                opened={drawerOpened}
                onClick={toggleDrawer}
                className={classes.hiddenDesktop}
                color="white"
              />
            </Group>
          </Group>
          <CBox display={{ md: "none", xl: "none" }}>
            <InputSearch onKeyDown={handleSearch} />
          </CBox>
        </CBox>
      </CBox>

      <MobileDrawer opened={drawerOpened} close={closeDrawer} />
    </CBox>
  );
}
