import { Stack, Flex, ActionIcon, Group, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import CartItem from "../../../shoppingCart/components/CartItem";
import { IItem } from "../../../items/types";
import { Routes } from "../../../../navigation/routes";

interface Props {
  items: IItem[];
}

function ShopCart({ items }: Props) {
  const navigate = useNavigate();
  return (
    <Stack>
      <Flex justify={"space-between"}>
        <Text fw={"bold"}>Mon Panier</Text>
        <ActionIcon onClick={() => navigate(Routes.shoppingCart)}>
          <IconChevronRight />
        </ActionIcon>
      </Flex>
      <Group grow>
        {items?.map((item) => (
          <CartItem
            key={item.id}
            item={item}
            size={80}
            w={"40%"}
            showQuantityInput={false}
          />
        ))}
      </Group>
    </Stack>
  );
}
export default ShopCart;
