import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IPaginationQueryParams } from "../../../api/types";
import { endPoints } from "../../../api/endPoints";
import { IItem } from "../types";
import { IMutationProps } from "../../types";
import { itemsService } from "../services/items";

export const useItems = (params?: IPaginationQueryParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [endPoints.items, params],
    queryFn: () => itemsService.findAll(params),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: true,
  });

  return {
    data: data?.data || [],
    meta: data?.meta || undefined,
    errorResponse: data?.meta === undefined && !rest.isLoading,
    ...rest,
  };
};

export const useItem = (uid: string) => {
  const { data, ...rest } = useQuery({
    queryKey: [`${endPoints.items}${uid}`],
    queryFn: () => itemsService.find(uid),
  });

  return {
    data: data,
    errorResponse: data?.id === undefined && !rest.isLoading,
    ...rest,
  };
};

export const useItemsMutation = ({
  onSuccess,
  onError,
  model,
}: IMutationProps<IItem>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: IItem) =>
      model
        ? itemsService.update(payload, `${model.uid}`)
        : itemsService.create(payload),
    onSuccess: (response) => {
      queryClient.invalidateQueries([endPoints.items]);
      onSuccess?.(response);
    },
    onError: (errors) => {
      onError?.(errors);
    },
  });
};

export const useItemDelete = ({
  onSuccess,
  onError,
}: IMutationProps<IItem>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uid: string) => itemsService.delete(uid),
    onSuccess: (response) => {
      queryClient.invalidateQueries([endPoints.items]);
      onSuccess?.(response);
    },
    onError: (errors) => {
      onError?.(errors);
    },
  });
};

export const useItemParser = (item?: IItem) => {
  if (item) {
    const newItem: IItem = {
      ...item,
      ...{
        colors: JSON.parse(`${item.colors}`),
        sizes: JSON.parse(`${item.sizes}`),
        size: JSON.parse(`${item.sizes}`)[0],
        color: JSON.parse(`${item.colors}`)[0],
      },
    };
    return newItem;
  }

  return undefined;
};

export const useParseItem = () => {
  const parser = (item: IItem) => {
    const newItem: IItem = {
      ...item,
      ...{
        colors: JSON.parse(`${item.colors}`),
        sizes: JSON.parse(`${item.sizes}`),
        size: JSON.parse(`${item.sizes}`)[0],
        color: JSON.parse(`${item.colors}`)[0],
      },
    };
    return newItem;
  };

  return parser;
};
