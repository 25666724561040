import { IItem } from "../../../features/items/types";
import { ActionProps } from "../../types";
import { ItemActions } from "./actions";

interface Props {
  favorites: IItem[];
  activePage: number;
}

const initialState: Props = {
  favorites: [],
  activePage: 1,
};

export function itemsReducer(
  state = initialState,
  action: ActionProps<ItemActions>
) {
  let nextState: typeof initialState;

  switch (action.type) {
    case ItemActions.ADD_FAVORITE:
      nextState = {
        ...state,
        favorites: [...state.favorites, action.value],
      };

      return nextState;

    case ItemActions.REMOVE_FAVORITE:
      nextState = {
        ...state,
        favorites: state.favorites.filter((item) => item.id !== action.value?.id),
      };

      return nextState;

    case ItemActions.SET_ACTIVE_PAGE:
      nextState = {
        ...state,
        activePage: action.value,
      };

      return nextState;
    default:
      return state;
  }
}
