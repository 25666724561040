import { Stack } from "@mantine/core";
import Categories from "../../../items/components/Categories";
import Items from "../../../items/components/Items";
import { Routes } from "../../../../navigation/routes";
import { Banner, Carousel } from "../../components";
import SectionHeader from "../../../../components/SectionHeader";
import TopItemsList from "../../../items/components/TopItemsList";
import { BaseCategories } from "../../components/BaseCategories";
import { useState } from "react";

function HomeScreen() {
  const [category, setCategory] = useState<number | undefined>();

  return (
    <Stack>
      <Carousel />
      <Stack mx={5}>
        <Categories />
        <Banner />
        <Stack mx={10}>
          <TopItemsList />
          <SectionHeader title="Articles récent" route={Routes.items} />
          <BaseCategories active={category} onChange={setCategory} />
          <Items categoryId={category} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default HomeScreen;
