import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { IItem } from "../../items/types";
import { ActionProps } from "../../../store/types";
import { ShoppingCartActions } from "../../../store/reducers/shoppingCartReducer/actions";
import { toast } from "../../../utils/toast";
import moment from "moment";

export const useAddItemToShopCart = () => {
  const addItem = (
    item: IItem,
    dispatch: Dispatch<AnyAction>,
    shoppingCartItems?: IItem[]
  ) => {
    const action: ActionProps<ShoppingCartActions> = {
      type: ShoppingCartActions.ADD_ITEM,
      value: {
        ...item,
        ...{ uid: `${moment(Date.now()).unix()}-${shoppingCartItems?.length || 0 + 1}` },
      },
    };
    dispatch(action);
    toast.show({
      title: "Panier",
      message: "Item ajouté avec succès",
    });
  };

  return addItem;
};

export const useCalculateShopCartSubTotal = () => {
  const total = (items: IItem[]) => {
    const subTotal = items
      .map((item) => item.salePrice * item.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    return subTotal;
  };

  return total;
};
